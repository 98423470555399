import cx from 'classnames'

type BulmaColor = 'dark' | 'primary' | 'link' | 'info' | 'success' | 'warning' | 'danger'

interface BulmaMessageProps {
  color: BulmaColor
  title?: React.ReactNode
  children: React.ReactNode
  className?: string
}

const BulmaMessage = ({ color, title, children, className }: BulmaMessageProps) => (
  <article className={cx('message', `is-${color}`, className)}>
    {title && <div className="message-header">{title}</div>}

    <div className="message-body">{children}</div>
  </article>
)

export default BulmaMessage
