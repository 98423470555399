import ConfirmRegistration from 'components/Registration/ConfirmRegistration'

const ConfirmRegistrationPage = () => (
  <div className="container is-max-desktop">
    <div className="section">
      <ConfirmRegistration />
    </div>
  </div>
)

export default ConfirmRegistrationPage
