import { gql, useMutation } from '@apollo/client'
import { BulmaMessage } from 'components/Bulma'
import { Link, navigate } from 'gatsby'
import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  ConfirmRegistrationMutation,
  ConfirmRegistrationMutationVariables,
} from './__generated__/ConfirmRegistrationMutation'

const confirmRegistrationMutation = gql`
  mutation ConfirmRegistrationMutation($input: ConfirmRegistrationInput!) {
    confirmRegistration(input: $input) {
      success
      code
      sku
      kitId
    }
  }
`

const ConfirmRegistration = () => {
  const [confirmRegistration, { loading, error, data }] = useMutation<
    ConfirmRegistrationMutation,
    ConfirmRegistrationMutationVariables
  >(confirmRegistrationMutation)

  const [confirmationKey] = useQueryParam('confirmationKey', StringParam)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    if (!confirmationKey) {
      return
    }

    const doRegConfirm = async () => {
      const resp = await confirmRegistration({
        variables: { input: { confirmationKey } },
      })

      if (resp.data.confirmRegistration.success) {
        await navigate(
          `/register-confirm-${resp.data.confirmRegistration.sku || 'ncov'}?kitId=${
            resp.data.confirmRegistration.kitId
          }`
        )
      }
    }

    doRegConfirm()
  }, [confirmationKey, confirmRegistration, setIsMounted])

  if (!isMounted || loading)
    return (
      <>
        <div className="loadable is-loading"></div>
        <div className="is-flex is-fullwidth is-justify-content-center mb-4">
          <div>Loading...</div>
        </div>
      </>
    )

  if (isMounted && !confirmationKey) {
    return <BulmaMessage color="danger">No confirmation key</BulmaMessage>
  }

  if (error)
    return (
      <BulmaMessage color="danger">
        Something went wrong, we've been notified. Please try again later or
        <Link to="/contact">contact our support team</Link>.
      </BulmaMessage>
    )

  if (data?.confirmRegistration?.code === 'NO_CONFIRMATION_FOUND') {
    return (
      <BulmaMessage color="warning" title="This confirmation link is not valid">
        <p>
          Your confirmation link may have expired - if you submitted your kit registration more than 12 hours ago please{' '}
          <Link to="/register">register</Link> again.
        </p>

        <p className="mt-3">
          If you registered less than 12 hours ago, please try clicking the link in your email again. If you still see
          this error, <Link to="/contact">contact our support team</Link>.
        </p>
      </BulmaMessage>
    )
  }

  if (data?.confirmRegistration?.code === 'KIT_ALREADY_COMPLETED') {
    return (
      <BulmaMessage color="info" title="This kit is already completed">
        <p>Your kit is already completed and you do not need to confirm your registration again.</p>
      </BulmaMessage>
    )
  }

  return null
}

export default ConfirmRegistration
